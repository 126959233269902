@import "https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta2/css/all.min.css";
@import "https://cdn.jsdelivr.net/npm/@creativebulma/bulma-tooltip@1.2.0/dist/bulma-tooltip.min.css";
@import url("https://fonts.googleapis.com/css2?family=Arvo:wght@700&family=Roboto+Slab:wght@100;400;500;700;900&family=Ultra&display=swap");

h1,
h2,
h3,
h4,
h5 {
  font-family: "Roboto Slab", serif;
  font-weight: 500 !important;
}

label.required:after {
  content: " *";
  color: hsl(348, 100%, 61%);
}

label.label {
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  margin-right: 0.5rem;
}

label.label + .icon {
  color: #3d3d3d60;
  transition: all 0.3s;
  text-transform: none;
}

label.label + .icon:hover {
  color: #3d3d3d;
}

span[data-tooltip].icon {
  border-bottom: 0;
}

[data-tooltip]:not(.is-disabled):before,
[data-tooltip]:not(.is-loading):before,
[data-tooltip]:not([disabled]):before {
  border-radius: 6px;
  text-transform: none;
}

input.input,
input.input:focus,
.select,
.select select,
.select select:focus {
  border-width: 0 0 1px 0;
  border-radius: 0;
  box-shadow: none;
}

input.input:focus,
.select select:focus,
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #3d3d3d;
}

table.table td.numeric,
table.table th.numeric {
  text-align: right;
}

table.table td.actions {
  white-space: nowrap;
}

table.table td.actions a {
  margin-right: 1rem;
}
table.table td.actions a:last-child {
  margin-right: 0;
}

.is-danger {
  color: #f14668;
}

.field input[type="checkbox"] {
  line-height: 40px;
  margin-right: 0.5rem;
}
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/
